<template>
    <div class="activity-container">
        <div class="activity-content-list">
            <van-empty
                image="search"
                v-if="activityList.length == 0 && load"
                description="暂无数据"
            />
            <make-card class="u-m-t-30" v-else :list="activityList"></make-card>
        </div>
    </div>
</template>

<script>
import makeCard from "@/page/activity/components/make-card.vue";
import { getMyCollect } from "@/service";
export default {
    components: {
        makeCard,
    },
    data() {
        return {
            load: false,
            classifyVal: 0,
            streetVal: "a",
            sortVal: "a",
            classify: [
                { text: "全部分类", value: 0 },
                { text: "演出", value: 1 },
                { text: "展览", value: 2 },
                { text: "讲座", value: 3 },
                { text: "培训", value: 4 },
                { text: "赛事", value: 5 },
                { text: "其他", value: 6 },
            ],
            street: [
                { text: "所属街道", value: "a" },
                { text: "好评排序", value: "b" },
                { text: "销量排序", value: "c" },
            ],
            sort: [
                { text: "智能排序", value: "a" },
                { text: "好评排序", value: "b" },
                { text: "销量排序", value: "c" },
            ],
            activityList: [],
        };
    },
    mounted() {
        this.getMyCollect();
    },
    methods: {
        async getMyCollect(options) {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                ...options,
            };
            const { data } = await getMyCollect(params);
            this.load = true;
            this.activityList = data.list || [];
        },
        handleActivity(options) {
            this.getMyCollect(options);
        },
    },
};
</script>

<style lang="scss" scoped>
.activity-container {
}
</style>